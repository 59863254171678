export class Peticionario {
  idUsuario: number;
  nombre: string;
  caracteres: Array<Caracteres>;
}

export class Caracteres {
  idCaracter: number;
  nombre: string;
}
