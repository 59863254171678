export class infoCatalogos {
    "id": number = 0;
    "nombre": string = "";
}

export class infoCatalogosCargos {
    "idCargo": number = 0;
    "nombre": string = "";
}

export class infoCatalogosOrganos {
    "idOrgano": number = 0;
    "nombre": string = "";
}

export class infoCatalogosRoles {
    "idRol": number = 0;
    "nombre": string = "";
}

export class infoCatalogosUsuariosNuevos {
    "id": number = 0;
    "nombre": string = "";
}

