export class Dashboard {
  resgistradas: number;
  proceso: number;
  finalizadas: number;
  esProceso: boolean;
  solicitudes:  Array<SolicitudesDashboard>
}

class SolicitudesDashboard {
  idSolicitud: number;
  clave: string;
  peticionario: string;
  fechaHora: string;
  asignacion: string;
  estatus: string;
  semaforo: number;
}

export class PeriodoReporte{
  desde: string = "";
  hasta: string ="";
}