import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './view/layout/admin/admin.component';
import { AuthGuard } from './data/guard/auth.guard';
import { RoleGuard } from './data/guard/role.guard';
import { Roles } from './model';

const rol = Roles;

const routes: Routes = [
  {
    path: 'login',
    loadChildren: './view/pages/login/login.module#LoginModule',
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full'
      },
      {
        path: 'inicio',
        canActivate: [RoleGuard],
        data: {
          rolUsuario: [rol.administrador],
        },
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule)
      },
      {
        path: 'peticiones',
        canActivate: [AuthGuard, RoleGuard],
        data: { rolUsuario: [rol.oficial, rol.partido, rol.partidoLocal, rol.administrador] },
        loadChildren: () => import('./view/pages/peticiones/peticiones.module').then(module => module.PeticionesModule)
      },
      {
        path: 'administrador',
        loadChildren: () => import('./view/pages/administrador/administrador.module').then(module => module.AdministradorModule)
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
