import { Adjuntos, Ubicacion } from "./ubicacion";

export class PeticionSolicitada {
  idUsuario: number;
  idUsuarioSolicitante: number;
  idCaracter: number = 0;
  idPartido: number = 0;
  idOrgano: number;

  peticionario: string = "";
  narracion: string = "";
  afectacion: string = "";
  procedimientoSancionador: string = "";
  hechosDenunciados: string = "";
  OprUbicaciones: Array<Ubicacion> = [];
  OprPeticionesAdjuntos: Array<Adjuntos> = [];
}


export class Solicitudes {
  solicitudes: Array<PeticionSolicitada> = [];
  idRol: number;
}
