export class Ubicacion {
  idMunicipio: number;
  idOrgano: number;
  nombreMunicipio: string;
  nombreOrgano: string;
  colonia: string;
  cp: string;
  calle: string;
  referencia: string;
  url: string;
  fechaPublicacion: string;
  latitud: string;
  longitud: string;
}

export class Adjuntos {
  folio: string;
  adjunto: string;
}

export class Organos {
  idOrgano: number;
  nombre: string;
}

export class Cargo {
  idCargoPartido: number;
  nombre: string;
}


export class Municipios {
  value: number;
  label: string;
  organos: Array<Organo>;
}

class Organo {
  value: number;
  label: string;
}
