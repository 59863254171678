export class ConfirmacionUsuario {
  mensaje: string;
  estatus: boolean;
  info: Usuario
}

class Usuario {
  token: string;
  idRol: number;
  nombre: string;
}

export class NuevosUsuarios {
  usuariosNuevos: Array<UsuariosNuevos>;
  organos: Array<Organos>;
  roles: Array<Roles>;
  cargos: Array<Cargos>;
}

class UsuariosNuevos {
  idUsuario: number;
  nombre: string;
}

class Organos {
  idOrgano: number;
  estatus: boolean;
  esOrdinario: boolean;
  esProceso: boolean;
  nombre: string;
}

class Roles {
  idRol: number;
  nombre: string;
}

class Cargos {
  idCargo: number;
  nombre: string;
}

export class ModeloUsuario {
  "idUsuario": number = null;
  "organo": string = "";
  "esOrdinario": boolean = false;
  "esProceso": boolean = false;
  "estatus": boolean = null;
  "esNuevo": boolean = true;
  "idCargo": number = null;
  "cargo": string = "";
  "idOrgano": number =  null;
  "nombre": string = "";
  "idRol": number = null;
  "rol": string = "";
}

export class ModeloUsuarioCorreos {
  "idUsuario": number = null;
  "nombreUsuario": string = "";
  "correo": string = "";
}