import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { ParametrosService } from './parametros.service';

import { environment } from 'src/environments/environment';

const url = environment.ruta;

@Injectable({
    providedIn: 'root'
})
export class AdministradorService {

    constructor(private _http: HttpClient, private _valor: ParametrosService) { }


    // ============================================================================================
    // Inicio Dashboard
    public obtenerDashoboard() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/dashboard`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public obtenerReportes(info) {
        let encabezado = this._valor.postToken();
        return this._http.post(`${url}Administrar/Reportes`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public accionCambioProceso(estatus) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}administrar/dashboard/proceso/${estatus}`, '', { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // Final
    // ============================================================================================

    // ============================================================================================
    // Inicio Usuarios

    public obtenerUsusarios() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/catalogos/usuarios`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public obtenerUsusariosNuevos() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/catalogos/usuariosNuevos`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public agregarNuevoUsuario(info) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}Administrar/usuarios`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public ModificarUsuario(info) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}Administrar/usuarios`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public obtenerCorreos(idUsuario) {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/Catalogos/CorreosAdicionales/${idUsuario}`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public accionCorreoNuevo(info) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}Administrar/CorreosAdicionales/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public accionCorreoEliminar(idUsuarioCorreo: number) {
        let encabezado = this._valor.getToken();
        return this._http.delete(`${url}Administrar/CorreosAdicionales/${idUsuarioCorreo}`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // Accion para eleiminar correos adicionales Verificar si se utiliza
    // async accionUsuarioEliminar(idUsuario: number) {
    //   let result: any = await this._http.delete(`${url}Administrar/usuarios/${idUsuario}`, { headers: this.encabezado }).pipe(map(this.infoDatos));
    //   return result;
    // }

    // Fin Usuarios
    // ============================================================================================

    // ============================================================================================
    // Inicio Organos

    public obtenerCatalagosOrganos() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/Catalogos/organos`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public obtenerCatalagosOrganosNuevos() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}administrar/catalogos/organosNuevos`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public accionCambioEstatus(info) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}administrar/organos`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public accionAgregarOrgano(info) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}administrar/organos`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // Fin Organos
    // ============================================================================================

    public obtenerTiposImprocedencia() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}Administrar/Catalogos/TiposImprocedencias/`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public guardarImprocedencia(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}Administrar/Improcedencias/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public actualizarImprocedencia(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}Administrar/Improcedencias/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public eliminarImprocedencia(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.delete(`${url}Administrar/Improcedencias/${info}`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // Tipos de Prevenciones
    public obtenerTiposPrevenciones() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}Administrar/Catalogos/TiposPrevenciones/`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public guardarPrevencion(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}Administrar/Prevenciones/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public actualizarPrevencion(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}Administrar/Prevenciones/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public eliminarPrevencion(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.delete(`${url}Administrar/Prevenciones/${info}`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // Articulos
    public obtenerArticulos() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}Catalogos/Articulos/`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public obtenerArticulosAdmin() {
        let encabezado = this._valor.getToken();
        return this._http.get(`${url}Administrar/Catalogos/Articulos/`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public guardarArticulo(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.post(`${url}Administrar/Articulos/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public actualizarArticulo(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.put(`${url}Administrar/Articulos/`, info, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    public eliminarArticulo(info: any) {
        let encabezado = this._valor.getToken();
        return this._http.delete(`${url}Administrar/Articulos/${info}`, { headers: encabezado }).pipe(map(this.infoDatos));
    }

    // obtenerReportes
    private infoDatos(res: Response) {
        let body: any = res;
        return body.info || {};
    }
}
